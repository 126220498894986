/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import {
    object, string, shape, func,
} from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import withStyles from '@material-ui/core/styles/withStyles';

import findCategoryPageByUrl from '../../../gql/queries/findCategoryPageByURL';

import { getBrand } from '../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';

const styles = (theme) => ({
    plantsContainer: {
        width: '100%',
        color: 'white',
        display: 'flex',
        gridGap: '15px',
        margin: '0 auto',
        padding: '1rem 0',
        maxWidth: '1270px',
        textAlign: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        justifyContent: 'center',
        '& img': {
            height: '21px',
            marginRight: '8px',
        },
        '& b': {
            fontWeight: '400',
            fontSize: '1.75rem',
            fontFamily: 'BebasNeue',
            textTransform: 'uppercase',
        },
        '& select': {
            width: '214px',
            height: '31px',
        },
        '& button': {
            color: 'black',
            height: '31px',
            fontWeight: '700',
            fontSize: '0.875rem',
            '&:hover': {
                backgroundColor: '#9bc318 !important',
            },
        },
        [theme.breakpoints.down('md')]: {
            display: 'inline-block',
            '& > *': {
                margin: '0 auto 10px',
                width: '90% !important',
            },
            '& :nth-child(2)': {
                marginRight: '5px',
                width: 'calc(45% - 5px) !important',
            },
            '& :nth-child(3)': {
                marginLeft: '5px',
                width: 'calc(45% - 5px) !important',
            },
        },
    },
    customSelect: {
        color: 'black',
        borderRadius: '0',
    },
});

const FAGF = ({
    classes, brand, trackEvent,
}) => {
    const history = useHistory();
    const [selectedValues, setSelectedValues] = useState({});

    const PRODUCT_QUERY = findCategoryPageByUrl(brand.domain, '/all-plants', 'standard_collections', '', '', '', 'USD', null);
    const { loading, data } = useQuery(PRODUCT_QUERY);

    if (loading) return '';

    const { productFacets = [] } = data?.findCategoryPageByUrl?.category || {};

    const heading = 'Quick Plant finder';

    const handleFAGFSubmit = () => {
        const selections = Object.values(selectedValues).join('&');
        trackEvent({
            eventCategory: 'Homepage',
            eventAction: 'Click',
            eventLabel: `FAGF - ${Object.values(selectedValues).join(', ')}`,
            eventName: 'feature_interaction',
        });
        history.push(`/c/all-plants?facet=${selections}`);
    };

    const handleDropdownChange = ({ target }) => {
        setSelectedValues({
            ...selectedValues,
            ...{
                [target.name]: target[target.selectedIndex].value,
            },
        });
    };

    return (
        <div className={classes.plantsContainer}>
            <div className="title-container">
                <img src="https://images.contentstack.io/v3/assets/blt4454dc0093319d35/blt437422935da0b7a3/63ecb96178d9365b0e5ab5e3/plant.png" alt={heading} />
                <b>{heading}</b>
            </div>
            {productFacets?.map((facetValue) => ['Care Level', 'Size', 'Light'].includes(facetValue.name) && (
                <select onChange={handleDropdownChange} name={facetValue.name} key={facetValue.name} className={classes.customSelect}>
                    <option selected>Choose Plant {facetValue.name}</option>
                    {facetValue.entries.map((facetOptionValue) => (
                        <option value={facetOptionValue.value} key={facetOptionValue.value}>{facetOptionValue.name}</option>
                    ))}
                </select>
            ))}
            <Button
                variant="contained"
                color="primary"
                onClick={handleFAGFSubmit}
            >
                Go<ArrowRightIcon />
            </Button>
        </div>
    );
};

FAGF.propTypes = {
    classes: object.isRequired,
    brand: shape({
        code: string.isRequired,
    }).isRequired,
    trackEvent: func.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

const mapDispatchToProps = {
    trackEvent: tagManagerActions.trackEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FAGF));
