/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Render a Panel content type
//
// Most likely, your object has a reference to a panel that was resolved.
// e.g. left_panel_ref.  Pass the first element in that ref.  E.g. <Panel data={left_panel_ref[0]} />

import React, { PureComponent } from 'react';
import { object, array } from 'prop-types';
import mbpLogger from 'mbp-logger';
import Copy from './Copy';
import LinkList from './LinkList/LinkList';
import generateValidCss from '../../helpers/contentstack/generateValidCss';

function sanitizeCSS(s) {
    return s.replace(/[^-_.a-zA-Z0-9]/g, '');
}

function Panel2(data) {
    const returnValue = [];
    if (!Array.isArray(data)) {
        return [];
    }
    if (data.message_collection && Array.isArray(data.message_collection)) {
        data.message_collection.forEach((item) => {
            const k = Object.keys(item)[0];
            switch (k)   {
                case 'copy':
                    returnValue.push(<Copy block={item.copy} />);
                    break;
                default:
                    mbpLogger.logWarning({ message: `Unhandled Panel component: ${sanitizeCSS(k)}` });
            }
        });
    }

    return returnValue;
}

Panel2.propTypes = {
    data: object.isRequired,
};

class Panel extends PureComponent {
    render() {
        const { data, styleData } = this.props;
        const hasStyles = styleData !== undefined && styleData.length > 0 ? generateValidCss(styleData, true) : {};
        // Check if Panel contained a Link List reference
        const linkListIndex = () => {
            const messageBlock = data?.message_collection || [];
            let i;
            let index = -1;
            for (i = 0; i < messageBlock.length; i += 1) {
                if ('link_list' in messageBlock[i]) {
                    index = i;
                }
            }
            return index;
        };

        let linkList;
        const linkListIndexOf = linkListIndex();
        if (linkListIndexOf !== -1) {
            linkList = <LinkList data={data?.message_collection?.[linkListIndexOf]} />;
        }

        return (
            <div style={{ position: 'relative' }}>
                <div style={hasStyles}>
                    {
                        // TODO: expand to include sashes and other overlays
                        data.overlays && (
                            <img
                                alt={data.overlays?.[0]?.whack?.file?.title}
                                src={data.overlays?.[0]?.whack?.file?.url}
                                style={{
                                    position: 'absolute',
                                    left: `${data.overlays?.[0]?.whack?.offset_left}em`,
                                    top: `${data.overlays?.[0]?.whack?.offset_top}em`,
                                }}
                            />
                        )
                    }
                    <Copy data={data.message_collection} />
                </div>
                {linkList}
            </div>
        );
    }
}

Panel.propTypes = {
    data: array.isRequired,
    styleData: array,
};
Panel.defaultProps = {
    styleData: [],
};

export default Panel;
