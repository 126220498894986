/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { object, bool } from 'prop-types';
import HeroSingleMobile from './Mobile/HeroSingleMobile';
import HeroSingleDesktop from './Desktop/HeroSingleDesktop';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import uniqueId from '../../../helpers/contentstack/uniqueId';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';

export class Hero extends PureComponent {
    render() {
        const { data, isBot } = this.props;
        if (data.reference?.[0]?.reference?.[0]) {
            const {
                presentation_type = '', height = '', reference = '', mobile_department_heading = '',  unique_selector = '', device,
            } = data.reference[0];
            const {
                linking = {},
                message_collection = [],
                message_styling = [],
                message_location = '',
                image = {},
                image_mobile = {},
                overlays = {},
            } = reference[0];
            const { dataUniqueId } = uniqueId(unique_selector);
            return (
                <div className="hero_block" data-uniqueid={dataUniqueId}>
                    <DesktopMobile
                        desktop={() => (
                            (device === 'Both' || device === 'Desktop') && (
                                <HeroSingleDesktop
                                    linking={linking}
                                    message_collection={message_collection}
                                    message_styling={message_styling}
                                    message_location={message_location}
                                    image={image}
                                    presentation_type={presentation_type}
                                    height={height}
                                    overlays={overlays}
                                    isBot={isBot}
                                />
                            )
                        )}
                        mobile={() => (
                            (device === 'Both' || device === 'Mobile') && (
                                <HeroSingleMobile
                                    data={data}
                                    image_mobile={image_mobile}
                                    message_collection={message_collection}
                                    linking={linking}
                                    mobile_department_heading={mobile_department_heading}
                                />
                            )
                        )}
                        data-uniqueid={dataUniqueId}
                    />
                </div>
            );
        }
        return <></>;
    }
}

Hero.propTypes = {
    data: object.isRequired,
    isBot: bool,
};
Hero.defaultProps = {
    isBot: false,
};
const mapStateToProps = (state) => ({
    isBot: getIsBot(state),
});

const enhance = compose(
    connect(mapStateToProps, null),
);
export default enhance(Hero);
