/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, shape, array, func,
} from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators } from 'redux';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import LinkList from '../../LinkList/LinkList';
import Panel2 from '../../Panel';
import useImageUrlQualityTransformation from '../../../../helpers/hooks/useImageUrlQualityTransformation';

const styles = () => ({
    mobileRoot: {
        display: 'block',
        margin: '0 auto',
    },
    bannerImage: {
        width: '100%',
        height: 'auto',
    },
    heading: {
        textAlign: 'center',
        fontSize: '1.7em',
        margin: '1em auto 0',
        fontWeight: '400',
    },
    shopby: {
        textAlign: 'center',
        margin: '.3em auto 1em auto',
        fontSize: '1.2em',
    },
});

// Check if banner contains a Link List reference
const linkListIndex = (data) => {
    let i;
    let index = -1;
    for (i = 0; i < data.length; i += 1) {
        if ('link_list' in data[i]) {
            index = i;
        }
    }
    return index;
};

const HeroSingleMobile = ({
    classes,
    image_mobile,
    message_collection,
    linking,
    trackEvent,
    mobile_department_heading,
}) => {
    const mobilePreprocessedUrl = image_mobile?.content_type !== 'video/mp4' ? image_mobile?.url : '';
    const mobile_image_url = useImageUrlQualityTransformation(mobilePreprocessedUrl);

    const hasDepartmentHeading = () => {
        if (mobile_department_heading) {
            return (
                <>
                    <Typography variant="h2" className={classes.heading}>{mobile_department_heading}</Typography>
                    <Typography variant="h5" className={classes.shopby}>Shop by Category:</Typography>
                </>
            );
        }
        return <></>;
    };

    if (image_mobile) {
        const {
            link: { href = '', title = '' }, tracking_event_action = '', tracking_event_label = '', tracking_event_category = '',
        } = linking;

        return (
            <Link
                onClick={() => {
                    trackEvent({
                        eventCategory: tracking_event_category || '',
                        eventAction: tracking_event_action || '',
                        eventLabel: tracking_event_label || '',
                        eventName: 'feature_interaction', // Requird for GA4 feature_click
                    });
                }}
                to={href}
                title={title}
                className={classes.mobileRoot}
            >
                <img
                    className={classes.bannerImage}
                    src={mobile_image_url}
                    alt={title}
                />
            </Link>
        );
    }
    if (message_collection.length) {
        const linkListIndexOf = linkListIndex(message_collection);
        if (linkListIndexOf !== -1) {
            return (
                <>
                    {hasDepartmentHeading()}
                    <LinkList data={message_collection[linkListIndexOf]} />
                </>
            );
        }
        return (
            <Panel2
                data={{ message_collection }}
            />
        );
    }
    if (mobile_department_heading) return hasDepartmentHeading();
    return null;
};

HeroSingleMobile.propTypes = {
    message_collection: array.isRequired,
    linking: shape({
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }).isRequired,
    }).isRequired,
    classes: object.isRequired,
    image_mobile: shape({
        url: string,
    }),
    trackEvent: func.isRequired,
    mobile_department_heading: string,
};

HeroSingleMobile.defaultProps = {
    mobile_department_heading: '',
    image_mobile: {
        url: '',
    },
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(HeroSingleMobile));
