/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, shape, string, array, func, bool,
} from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import Panel2 from '../../Panel';
import useImageUrlQualityTransformation from '../../../../helpers/hooks/useImageUrlQualityTransformation';

const styles = (theme) => ({
    root: {
        boxSizing: 'border-box',
        padding: theme.spacing(3, 0, 0, 0),
        display: 'block',
        height: '400px',
        margin: theme.palette.heroSingle?.margin,
        textDecoration: 'none',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: theme.palette.common?.black || '#000000', // TODO: Font color should come from CMS
    },
    scrap: {
        // margin: `0 auto ${theme.spacing(3)}px auto`,
        padding: '10x 20px',
        textAlign: 'center',
        fontSize: '34px',
        color: theme.palette.common?.black || '#000000', // TODO: Font color should come from CMS
    },
    headline: {
    },
    ctaLink: {
        textDecoration: 'none',
    },
    cta: {
        boxSizing: 'border-box',
        display: 'block',
        margin: '0 auto',
        fontSize: '18px',
        fontWeight: '800',
        borderRadius: '2px',
    },
    contentSection: {
        margin: '0 auto',
        textAlign: 'center',
    },
    float: {
        marginLeft: '0px',
    },
});
// const hasLinkList = {};
const position = (message_location) => {
    if (message_location !== undefined && message_location !== null) {
        let margin = 'center';
        switch (message_location.toLowerCase()) {
            case 'left':
                margin = { margin: '0 auto 0 0' };
                break;
            case 'center':
                margin = { margin: '0 auto' };
                break;
            case 'right':
                margin = { margin: '0 0 auto 0' };
                break;
            default:
                margin = { margin: '0 auto' };
                break;
        }
        return margin;
    }
    return {};
};

const HeroSingleDesktop = ({
    classes,
    linking,
    message_collection,
    message_styling,
    message_location,
    image,
    presentation_type,
    height,
    overlays,
    trackEvent,
    isBot,
    tabletMobImage,
    image_mobile,
}) => {
    if (!linking && !message_styling && !message_collection) {
        return null;
    }
    if (image && image.url && linking.link) {
        const {
            link: { href = '', title = '' }, tracking_event_action = '', tracking_event_label = '', tracking_event_category = '',
        } = linking;

        const imagePath = useImageUrlQualityTransformation(!isBot && `${tabletMobImage ? image_mobile?.url : image?.url}?quality=1&auto=webp&optimize={medium}`);
        const panelData = {
            message_collection,
            overlays,
        };
        return (
            <Link
                onClick={() => {
                    trackEvent({
                        eventCategory: tracking_event_category || '',
                        eventAction: tracking_event_action || '',
                        eventLabel: tracking_event_label || '',
                        eventName: 'feature_interaction', // Requird for GA4 feature_click
                    });
                }}
                to={href}
                title={title}
                style={{
                    backgroundImage: `url(${imagePath})`,
                    width: `${presentation_type?.indexOf('Fixed') !== -1 ? '1400px' : '100%'}`,
                    height: `${height}px`,
                }}
                className={classes.root}
            >
                {isBot && <img src={imagePath} alt={image.title} />}
                <div className={classes.contentSection} style={position(message_location)}>
                    <Panel2
                        className={classes.headline}
                        data={panelData}
                        styleData={message_styling}
                    />
                </div>
            </Link>
        );
    }
    return <></>;
};

HeroSingleDesktop.propTypes = {
    classes: object.isRequired,
    linking: shape({
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }).isRequired,
    }).isRequired,
    message_collection: array.isRequired,
    overlays: object.isRequired,
    message_styling: array.isRequired,
    message_location: string.isRequired,
    image: shape({
        url: string.isRequired,
    }).isRequired,
    presentation_type: string.isRequired,
    height: string.isRequired,
    trackEvent: func.isRequired,
    isBot: bool,
    tabletMobImage: bool,
    image_mobile: shape({
        url: string,
        content_type: string,
    }),
};
HeroSingleDesktop.defaultProps = {
    isBot: false,
    image_mobile: {},
    tabletMobImage: false,
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(HeroSingleDesktop));
